import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Lazy load components for better performance
const Home = lazy(() => import("./Components/Home"));
const JoinNow = lazy(() => import("./Components/JoinNow"));
const Login = lazy(() => import("./Components/Login"));
const OtpVerification = lazy(() => import("./Components/OtpVerification"));
const CreateProfile = lazy(() => import("./Components/CreateProfile"));
const Onboarding = lazy(() => import("./Components/Onboarding"));
const ClassBooking = lazy(() => import("./Components/ClassBooking"));
const ClassDetails = lazy(() => import("./Components/ClassDetails"));
const StudioDetails = lazy(() => import("./Components/StudioDetails"));
const Trainerinfo = lazy(() => import("./Components/Trainerinfo"));
const Slots = lazy(() => import("./Components/Slots"));
const ForgotPassword = lazy(() => import("./Components/ForgotPassword")); // Add Forgot Password Component
const ClassData = lazy(() => import("./Components/classData")); // ClassData Component
const ConfirmBooking = lazy(() => import("./Components/Confirmbooking"));
const Bookedclass = lazy(() => import("./Components/Bookedclass")); // Import Bookedclass

const NotFound = () => (
  <div style={{ textAlign: "center", padding: "50px" }}>
    <h1>404</h1>
    <p>The page you're looking for doesn't exist.</p>
    <a href="/home">Go to Home</a>
  </div>
);

function App() {
const { state } = useLocation();

  return (
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Redirect "/" to "/home" */}
          <Route path="/" element={<Navigate to="/home" replace />} />

          {/* Main routes */}
          <Route path="/home" element={ <Home />}/>
          <Route path="/join" element={<JoinNow />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/classbooking" element={ <ClassBooking /> } />
          <Route path="/classdetails" element={<ClassDetails />   } />

          {/* Dynamic route for studio details */}
          <Route path="/studio-details/:id" element={<StudioDetails /> } />
          <Route path="/classData" element={<ClassData />  } />

          {/* Login and OTP verification routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/create-profile" element={<CreateProfile />} />

          {/* Forgot Password Route */}
          <Route path="/forgotpassword" element={<ForgotPassword />} />

          {/* Additional routes */}
          <Route path="/Trainerinfo" element={<Trainerinfo />} />
          <Route path="/slots" element={<Slots />  } />

          <Route path="/confirmbooking" element={<ConfirmBooking />  } />
          <Route path="/bookedclass" element={<Bookedclass /> } />

          {/* Fallback route for undefined paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
  );
}

export default App;
